import styled from "@emotion/styled";
import { WHITE } from "../../utils/colors";

export const ArrowContainer = styled.span`
  width: 28px;
  height: 23px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PurpleGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  :hover {
    text-decoration: underline;
    color: ${WHITE};
    // RGB interpretation of PURPLE.
    // TODO(charlie): Translate dynamically to avoid hard-coding here.
    background-color: rgba(98, 16, 255, 0.8);
  }

  transition: background-color 0.2s ease;
`;

export const YellowGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  :hover {
    text-decoration: underline;
    color: ${WHITE};
    // RGB interpretation of YELLOW.
    // TODO(charlie): Translate dynamically to avoid hard-coding here.
    background-color: rgba(255, 188, 11, 0.8);
  }

  transition: background-color 0.2s ease;
`;
