import Link from "next/link";
import squareOverlay from "../../images/04_Photo_Overlays/PNG/Spring_Photo_Overlay_Square.png";
import technologyImage from "../../images/Other/PNG/CellRender.png";
import Arrow from "../../images/Other/SVG/Arrow.svg";
import { MD } from "../blog-constants";
import Image from "../Image";
import { ResponsiveSpacing } from "../layout";
import { H3, Superheader } from "../typography";
import { ArrowContainer, PurpleGradient } from "./layout";

export default function TechnologyCard() {
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "100%",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Image
          placeholder={"blur"}
          src={technologyImage}
          unoptimized
          layout={"raw"}
          width={630}
          height={630}
          sizes={`(max-width: ${MD}px) 100vw, 33vw`}
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Image
          placeholder={"blur"}
          src={squareOverlay}
          unoptimized
          layout={"raw"}
          width={630}
          height={630}
          sizes={`(max-width: ${MD}px) 100vw, 33vw`}
        />
      </div>
      <Link href={"/technology"}>
        <PurpleGradient>
          <div className="flex flex-col justify-end w-full h-full">
            <ResponsiveSpacing
              xs={{
                paddingTop: 24,
                paddingLeft: 24,
                paddingBottom: 24,
                paddingRight: 24,
              }}
              md={{
                paddingTop: 30,
                paddingLeft: 30,
                paddingBottom: 30,
                paddingRight: 30,
              }}
            >
              <Superheader>Our Technology</Superheader>
              <div
                className="flex flex-row items-end"
                style={{ marginTop: 14, marginRight: 18 }}
              >
                <H3 style={{ marginRight: 8 }}>
                  Intersection of human expertise and artificial intelligence
                </H3>
                <ArrowContainer>
                  <Arrow />
                </ArrowContainer>
              </div>
            </ResponsiveSpacing>
          </div>
        </PurpleGradient>
      </Link>
    </div>
  );
}
