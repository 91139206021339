/**
 * Component to render a series of "cards" in a row or column.
 *
 * The layout direction will be determined based on available screen width.
 *
 * The component expects to receive, as children, the individual cards. E.g.:
 *
 *   <CardsSection>
 *     <TeamCard />
 *     <BlogCard />
 *     <CareersCard />
 *   </CardsSection>
 */

import styled from "@emotion/styled";
import { Children, ReactElement } from "react";

import { MAX_FULL_SCREEN_WIDTH } from "../constants";

const Bordered = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  &:last-of-type {
    border-right: none;
  }

  @media (min-width: ${MAX_FULL_SCREEN_WIDTH}px) {
    border-right: 1px solid rgba(255, 255, 255, 0.3);

    &:last-of-type {
      border-right: 1px solid rgba(255, 255, 255, 0.3);
    }

    &:first-of-type {
      border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
`;

export default function CardsSection({
  children,
}: {
  children: ReactElement[];
}) {
  return (
    <div className={"flex flex-wrap flex-row"}>
      {Children.map(children, (child) => (
        <Bordered className={"w-full md:w-4/12"}>{child}</Bordered>
      ))}
    </div>
  );
}
