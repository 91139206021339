import Link from "next/link";
import gradient1Image from "../../images/03_Color/Gradients/PNG/Spring_Cell_Module_WhiteArtboard_1.png";
import Arrow from "../../images/Other/SVG/Arrow.svg";
import { MD } from "../blog-constants";
import Image from "../Image";
import { ResponsiveSpacing } from "../layout";
import { H3, Superheader } from "../typography";
import { ArrowContainer, YellowGradient } from "./layout";

export default function BlogCard() {
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "100%",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Image
          placeholder={"blur"}
          src={gradient1Image}
          unoptimized
          layout={"raw"}
          width={630}
          height={630}
          sizes={`(max-width: ${MD}px) 100vw, 33vw`}
        />
      </div>
      <Link href={"/blog/megamap"}>
        <YellowGradient>
          <div className="flex flex-col justify-end w-full h-full">
            <ResponsiveSpacing
              xs={{
                paddingTop: 24,
                paddingLeft: 24,
                paddingBottom: 24,
                paddingRight: 24,
              }}
              md={{
                paddingTop: 30,
                paddingLeft: 30,
                paddingBottom: 30,
                paddingRight: 30,
              }}
            >
              <Superheader>Blog</Superheader>
              <div
                className="flex flex-row items-end"
                style={{ marginTop: 14, marginRight: 18 }}
              >
                <H3 style={{ marginRight: 8 }}>
                  MegaMap, fueled by a suite of machine learning models
                </H3>
                <ArrowContainer>
                  <Arrow />
                </ArrowContainer>
              </div>
            </ResponsiveSpacing>
          </div>
        </YellowGradient>
      </Link>
    </div>
  );
}
