/**
 * Components to load static images from Cloudflare Workers.
 *
 * This is useful for images that are bundled with the codebase, which are then deployed via
 * Cloudflare Pages, and resized via Cloudflare Workers.
 */
import NextImage, { ImageProps } from "next/image";

function cloudflareWorkerLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) {
  if (!quality) {
    quality = 75;
  }
  return `https://images.springdiscovery.com?width=${width}&quality=${quality}&image=${process.env.CF_PAGES_URL}${src}`;
}

export default function Image(props: ImageProps) {
  if (process.env.NODE_ENV === "development") {
    return <NextImage loading={"eager"} {...props} />;
  } else {
    return (
      <NextImage loading={"eager"} {...props} loader={cloudflareWorkerLoader} />
    );
  }
}
