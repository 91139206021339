/**
 * Component to render the investors section.
 */

import styled from "@emotion/styled";
import Link from "next/link";
import businessInsiderImage from "../images/Press/PNG/Business_Insider.png";
import felicisVenturesImage from "../images/Investors/PNG/Felicis_Ventures.png";
import firstRoundImage from "../images/Investors/PNG/First_Round.png";
import generalCatalystImage from "../images/Investors/PNG/General_Catalyst.png";
import longevityFundImage from "../images/Investors/PNG/Longevity_Fund.png";
import Arrow from "../images/Other/SVG/Arrow.svg";
import WallStreetJournal from "../images/Press/SVG/Wall_Street_Journal.svg";
import { PURPLE, WHITE } from "../utils/colors";
import { LG, MD, SM } from "./constants";
import {
  LeftHalfPageContentNoGutter,
  ResponsiveSpacing,
  RightHalfPageContentNoGutter,
} from "./layout";
import Image from "./Image";
import { H2 } from "./typography";

const IconsContainer = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: ${LG}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Rule = styled.hr`
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 0;
  margin-bottom: 0;
`;

const InvestorIcon = styled.span`
  width: 172px;
  height: 86px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SamAltman = styled.span`
  text-transform: uppercase;
  color: ${WHITE};

  font-size: 24px;
  line-height: 86px;

  width: 172px;
  height: 86px;

  text-align: center;
`;

export default function InvestorsSection() {
  return (
    <>
      <div
        className={"w-full flex flex-col items-center"}
        id={"investors"}
        style={{ backgroundColor: PURPLE }}
      >
        <div className={"w-full"} style={{ maxWidth: 980 }}>
          <ResponsiveSpacing
            xs={{ paddingBottom: 48, paddingTop: 34 }}
            lg={{ paddingBottom: 68, paddingTop: 60 }}
          >
            <H2 className={"text-center"}>Investors</H2>
          </ResponsiveSpacing>

          <ResponsiveSpacing
            xs={{ paddingBottom: 34 }}
            lg={{ paddingBottom: 82 }}
          >
            <IconsContainer className={"flex-grow"}>
              <InvestorIcon>
                <Image unoptimized src={generalCatalystImage} />
              </InvestorIcon>
              <InvestorIcon>
                <Image unoptimized src={firstRoundImage} />
              </InvestorIcon>
              <InvestorIcon>
                <Image unoptimized src={longevityFundImage} />
              </InvestorIcon>
              <InvestorIcon>
                <Image unoptimized src={felicisVenturesImage} />
              </InvestorIcon>
              <InvestorIcon>
                <SamAltman>Sam Altman</SamAltman>
              </InvestorIcon>
            </IconsContainer>
          </ResponsiveSpacing>
        </div>

        <Rule />
      </div>
    </>
  );
}
